import React from "react";
import mobIcon from "../../images/mobIcon.webp";
import MyHero from "../../images/Logo2.png";
import { FaArrowRight, FaFacebookSquare, FaInstagramSquare, FaYoutubeSquare, FaLinkedin, FaTwitterSquare } from "react-icons/fa";

const About = () => {
  return (
    <section id="about" className="container about">
      <div className="content">
        <div className="frist-p">
          <img className="myhero-word" src={MyHero} alt="logo"/>
          <p>IS NOW</p>
        </div>

        <p className="second-p">
          ON SOCIAL MEDIA
        </p>

        <div className="social">
          <div className="arrow"><FaArrowRight className="arrow-animat"/></div>
          <a href="https://www.facebook.com/Myheroapplication?utm_medium=social&utm_source=heylink.me" target="blank">
            <FaFacebookSquare className="face" />
          </a>
          <a href="https://www.instagram.com/myheroapp_/?utm_medium=social&utm_source=heylink.me" target="blank">
            <FaInstagramSquare className="insta" />
          </a>
          <a href="https://www.linkedin.com/company/myhero1/?utm_medium=social&utm_source=heylink.me" target="blank">
            <FaLinkedin className="linked" />
          </a>
          <a href="https://www.youtube.com/channel/UCHgnVvn7TlQl47tO-iOsiyg?utm_medium=social&utm_source=heylink.me" target="blank">
            <FaYoutubeSquare className="youtube" />
          </a>
          <a href="https://twitter.com/myhero_shop?utm_medium=social&utm_source=heylink.me" target="blank">
            <FaTwitterSquare className="twitter" />
          </a>
        </div>

        <div className="description">
          <span className="myhero-des"><img src={MyHero} alt="mockup"/></span>
            helps you to find the best medical supplies or equipment with the best quality and price.
            You can find all the medical products you need on MY HERO!
        </div>
        <a  href='mailto:myheroapplication@gmail.com' target='blank'><button className='btn btn-download'><span>Contact US</span></button></a>
      </div>

      <div className="mockup">
        <img src={mobIcon} alt="mockup" />
      </div>
    </section>
  );
};

export default About;
