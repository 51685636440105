import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../admin/Layout";
import { toast } from "react-toastify";
import axios from "axios";

export default function AddUser() {
  const navigate = useNavigate();
  const [disableBtn, setDisbale] = useState(false);
  const [form, setForm] = useState({
    user_firstname: "",
    user_lastname: "",
    user_email: "",
    user_password: "",
    user_mobile: "",
    user_role: "",
  });

  const back = () => {
    navigate("/users");
  };

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setDisbale(true);
    axios
      .post(`/api/addhighrole`, { ...form })
      .then((response) => {
        setDisbale(false);
        toast.success("User Added Successfully");
        navigate("/users");
      })
      .catch((err) => {
        setDisbale(false);
        console.log(err.message)
        toast.error("Error 😔");
      });
  };
  
  return (
    <Layout>
      <form className="add-user-form" onSubmit={handleSubmit}>
        <h2>&gt;&gt; Add New User</h2>

        <div className="fields">
          <div className="input-group">
            <label>First Name</label>
            <input
              type="text"
              name="user_firstname"
              value={form.user_firstname}
              required
              onChange={handleChange}
            />
          </div>

          <div className="input-group">
            <label>Last Name</label>
            <input
              type="text"
              name="user_lastname"
              value={form.user_lastname}
              required
              onChange={handleChange}
            />
          </div>

          <div className="input-group">
            <label>E-mail</label>
            <input
              type="text"
              name="user_email"
              value={form.user_email}
              required
              onChange={handleChange}
            />
          </div>

          <div className="input-group">
            <label>Phone</label>
            <input
              type="number"
              name="user_mobile"
              value={form.user_mobile}
              required
              onChange={handleChange}
            />
          </div>

          <div className="input-group">
            <label>Password</label>
            <input
              type="password"
              name="user_password"
              value={form.user_password}
              required
              onChange={handleChange}
            />
          </div>

          <div className="input-group">
            <label>Role</label>
            <select
              name="user_role"
              value={form.user_role}
              required
              onChange={handleChange}
            >
              <option value="" defaultValue>
                Choose Role
              </option>
              <option value="Admin">Admin</option>
              <option value="Vendor">Vendor</option>
            </select>
          </div>

          <div className="buttons">
            <button onClick={back} className="btn-delete"> Back </button>
            <button disabled={disableBtn}> Add User </button>
          </div>
        </div>
      </form>
    </Layout>
  );
}
