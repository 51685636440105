import React, { useState, useEffect } from "react";
import { FaBars, FaTimes, FaUsers, FaShoppingCart } from "react-icons/fa";
import { AiFillDashboard } from "react-icons/ai";
import { BsInboxesFill } from "react-icons/bs";
import { NavLink } from "react-router-dom";
import Logo from "../../images/Logo.png";
import { decodeToken } from "react-jwt";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { authState } from "../../recoil";
import { FaDoorOpen } from "react-icons/fa";

const Sidebar = () => {
  const [show, setShow] = useState(false);
  const showSidebar = () => {
    setShow(!show);
  };
  const authToken = useRecoilValue(authState);
  const setToken = useSetRecoilState(authState);
  const [token, setDecodeToken] = useState("");

  useEffect(() => {
    if (authToken) {
      const decode = decodeToken(authToken);
      setDecodeToken(decode);
    }
  }, [authToken]);

  const handleLogout = () => {
    localStorage.removeItem("token");
    setToken(null);
  };

  return (
    <nav
      className={
        show ? "navigation-sidebar mobile-sidebar" : "navigation-sidebar"
      }
    >
      <button className="mobile-menu" onClick={showSidebar}>
        {show ? <FaTimes /> : <FaBars />}
      </button>

      <div className="content">
        <div className="side-bar-logo">
          <img src={Logo} alt="logo" />
        </div>

        <ul>
          <li>
            <NavLink to="/dashboard">
              {" "}
              <AiFillDashboard className="icon" /> Dashboard{" "}
            </NavLink>
          </li>
          {token[0]?.user_role === "Admin" && (
            <>
              <li>
                <NavLink to="/users">
                  {" "}
                  <FaUsers className="icon" /> Users{" "}
                </NavLink>
              </li>
            </>
          )}
          {token[0]?.user_role === "Vendor" && (
            <>
              <li>
                <NavLink to="/products">
                  {" "}
                  <BsInboxesFill className="icon" /> Products{" "}
                </NavLink>
              </li>
              <li>
                <NavLink to="/orders">
                  {" "}
                  <FaShoppingCart className="icon" /> Orders{" "}
                </NavLink>
              </li>
            </>
          )}

          {/* <li>
                <NavLink to='/settings'> <AiFillSetting className='icon' /> Settings </NavLink>
            </li> */}
        </ul>

        <button className="btn logout-btn" onClick={handleLogout}>
          <FaDoorOpen className="logout-icon" /> Logout{" "}
        </button>
      </div>
    </nav>
  );
};

export default Sidebar;
