import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import MUIDataTable from "mui-datatables";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { createTheme } from "@material-ui/core/styles";
import Layout from "../../components/admin/Layout";
import { useRecoilValue } from "recoil";
import { authState } from "../../recoil";

const Orders = () => {
  const authToken = useRecoilValue(authState);
  const [orders, setOrders] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [totalPage, setTotalPage] = useState("");

  useEffect(() => {
    axios
      .post("/api/getvendorsorders")
      .then((res) => {
        // const newOrders = res?.data.map((el) => {
        //   return {
        //     ...el,
        //     user_firstname: el.user_firstname + " " + el.user_lastname,
        //   };
        // });
        // setOrders(newOrders);
        setOrders(res.data);
        // setTotalPage(res.data.pages);
      })
      .catch((err) => {
        toast.error("Failed to get Orders");
        document.location.reload(true);
      });
  }, [authToken]);

  const handelIncrement = () => {
    axios
      .post("/api/getorders", {
        page: pageCount + 1,
      })
      .then((res) => {
        if (res.data.pages !== pageCount) {
          setOrders(res.data.data);
          setTotalPage(res.data.pages);
          setPageCount(pageCount + 1);
        }
      })
      .catch((err) => {
        toast.error("Failed to get Orders");
      });
  };

  const handelDecrement = () => {
    axios
      .post("/api/getorders", {
        page: pageCount - 1,
      })
      .then((res) => {
        setOrders(res.data.data);
        setTotalPage(res.data.pages);
        setPageCount(pageCount - 1);
      })
      .catch((err) => {
        toast.error("Failed to get Orders");
      });
  };

  const handelAccept = (tableMeta) => {
    axios
      .patch('/api/updateorder', {
        order_status: "Accepted",
        orderId: orders[tableMeta.rowIndex].id,
      })
      .then((res) => {
        toast.success("Order Accepted");
        document.location.reload(true);
      })
      .catch((err) => {
        toast.error("Failed to Accept an Order");
      });
  };

  const handelReject = (tableMeta) => {
    axios
      .patch('/api/updateorder', {
        order_status: "Rejected",
        orderId: orders[tableMeta.rowIndex].id,
      })
      .then((res) => {
        toast.success("Order Rejected");
        document.location.reload(true);
      })
      .catch((err) => {
        toast.error("Failed to Reject an Order");
      });
  };

  const handelDelivery = (tableMeta) => {
    axios
      .patch('/api/updateorder', {
        order_status: "Delivered",
        orderId: orders[tableMeta.rowIndex].id,
      })
      .then((res) => {
        toast.success("Order Delivered");
        document.location.reload(true);
      })
      .catch((err) => {
        toast.error("Failed to Delivered an Order");
      });
  };

  const columns = [
    { name: "id", label: "ID", options: { filterType: "textField" } },
    { 
      name: "product_title",
      label: "Name",
      options: {
        customBodyRender: (value, tableMeta) => {
          return <p>{orders[tableMeta.rowIndex]?.product?.product_title}</p>;
        },
      },
    },
    // { 
    //   name: "prouduct_describtion",
    //   label: "Describtion",
    //   options: {
    //     customBodyRender: (value, tableMeta) => {
    //       return <p>{orders[tableMeta.rowIndex]?.product?.prouduct_describtion}</p>;
    //     },
    //   },
    // },
    // { 
    //   name: "product_price",
    //   label: "Price",
    //   options: {
    //     customBodyRender: (value, tableMeta) => {
    //       return <p>{orders[tableMeta.rowIndex]?.product?.product_price}</p>;
    //     },
    //   },
    // },
    // { 
    //   name: "prouduct_brand",
    //   label: "Brand",
    //   options: {
    //     customBodyRender: (value, tableMeta) => {
    //       return <p>{orders[tableMeta.rowIndex]?.product?.prouduct_brand}</p>;
    //     },
    //   },
    // },
    // { 
    //   name: "prouduct_type",
    //   label: "Type",
    //   options: {
    //     customBodyRender: (value, tableMeta) => {
    //       return <p>{orders[tableMeta.rowIndex]?.product?.prouduct_type}</p>;
    //     },
    //   },
    // },
    // { 
    //   name: "product_city",
    //   label: "City",
    //   options: {
    //     customBodyRender: (value, tableMeta) => {
    //       return <p>{orders[tableMeta.rowIndex]?.product?.product_city}</p>;
    //     },
    //   },
    // },
    { name: "order_quantity", label: "Quantity", options: { filterType: "textField" } },
    { 
      name: "order_status", 
      label: "Order Status", 
      options: { 
        filterType: "dropdown", 
        filterOptions: ["Pending", "Rejected", "Accepted", "Delivered"],
        customBodyRender: (value, tableMeta, updateValue) => {
          if(orders[tableMeta.rowIndex].order_status === "Accepted" || orders[tableMeta.rowIndex].order_status === "Delivered"){
            return (
              <a href={`/order/${orders[tableMeta.rowIndex].id}`}>
                {value}
              </a>
            );
          }else {
            return (
              <div>
                {value}
              </div>
            );
          }
        },
      } 
    },
    {
      name: "",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          if(orders[tableMeta.rowIndex].order_status === "Pending"){
            return (
              <button
                className="order-btn acc-btn"
                onClick={() => {
                  handelAccept(tableMeta);
                }}
              >
                Accept
              </button>
            );
          } else if(orders[tableMeta.rowIndex].order_status === "Accepted"){
            return (
              <button
                className="order-btn delevered-btn"
                onClick={() => {
                  handelDelivery(tableMeta);
                }}
              >
                Delivered
              </button>
            );
          }
        },
      },
    },
    {
      name: "",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          if(orders[tableMeta.rowIndex].order_status === "Pending")
            return (
              <button
                className="order-btn rej-btn"
                onClick={()=> {
                  handelReject(tableMeta);
                }}
              >
                Reject
              </button>
            );
        },
      },
    },
  ];

  const options = {
    selectableRows: "multiple",

    customFooter: ( count, page, rowsPerPage, changeRowsPerPage, changePage, textLabels ) => {
      return (
        <div className="pagenation">
          {/* {pageCount > 0 && (
            <button className="left-arr" onClick={handelDecrement}>
              &lt;
            </button>
          )}

          {pageCount !== totalPage && (
            <button className="right-arr" onClick={handelIncrement}>
              &gt;
            </button>
          )} */}
        </div>
      );
    },
  };

  const getMuiTheme = () =>
    createTheme({
      overrides: {
        MUIDataTableToolbar: {
          actions: {
            display: "flex",
            flexDirection: "row",
            flex: "initial",
          },
        },
        MuiToolbar: {
          root: {
            color: "#1fd0b4",
          },
        },
        MuiTableHead: {
          root: {
            color: "#1fd0b4",
          },
        },
      },
    });

  return (
    <Layout>
      <div className="orders">
        <MuiThemeProvider theme={getMuiTheme()}>
          <MUIDataTable
            title={"Orders"}
            data={orders}
            columns={columns}
            options={options}
          />
        </MuiThemeProvider>
      </div>
    </Layout>
  );
};
export default Orders;

