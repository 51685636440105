import React, { useEffect } from "react";
import Layout from "../../components/admin/Layout";
import logo from "../../images/myHeroDashboard.png";

const Dashboard = () => {
  return (
    <Layout>
      <div className="dash">
        <img className="logo-dashboard" src={logo} alt="Logo" />
      </div>
    </Layout>
  );
};

export default Dashboard;
