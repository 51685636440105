import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import Layout from "../admin/Layout";
import { toast } from "react-toastify";
import axios from "axios";

const AddProduct = () => {
  const navigate = useNavigate();
  const [disableBtn, setDisbale] = useState(false);
  const [data, setData] = useState({
    product_title: "",
    prouduct_describtion: "",
    product_price: "",
    product_city: "",
    prouduct_brand: "",
    prouduct_type: "",
  });
  const [images, setImages] = useState([]);

  const back = () => {
    navigate("/products");
  };

  const handleChange = (e) => {
    setData({...data, [e.target.name]: e.target.value});
  };

  const handleUpload = (e) => {
    setImages(e.target.files);
  };

  const headers = {
    'Content-Type': 'multipart/form-data',
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // setDisbale(true);
    var bodyFormData = new FormData();
    await bodyFormData.set('product_title', data.product_title);
    await bodyFormData.set('prouduct_describtion', data.prouduct_describtion);
    await bodyFormData.set('product_price', data.product_price);
    await bodyFormData.set('product_city', data.product_city);
    await bodyFormData.set('prouduct_brand', data.prouduct_brand);
    await bodyFormData.set('prouduct_type', data.prouduct_type);
    for(const file of images)
      await bodyFormData.append('images', file);
    
    axios
      .post(`/api/addproduct`, bodyFormData, { headers: headers })
      .then((response) => {
        setDisbale(false);
        toast.success("Product added Successfully");
        navigate("/products");
      })
      .catch((err) => {
        setDisbale(false);
        toast.error("Error 😔");
        console.log(err)
      });
  };
  return ( 
    <Layout>
      <form className="add-user-form" onSubmit={handleSubmit}>
        <h2>&gt;&gt; Add New Product</h2>

        <div className="fields">
          <div className="input-group">
            <label>Name</label>
            <input
              type="text"
              name="product_title"
              required
              onChange={handleChange}
            />
          </div>

          <div className="input-group">
            <label>Describtion</label>
            <input
              type="text"
              name="prouduct_describtion"
              required
              onChange={handleChange}
            />
          </div>

          <div className="input-group">
            <label>Price</label>
            <input
              type="number"
              name="product_price"
              required
              onChange={handleChange}
            />
          </div>
          
          <div className="input-group">
            <label>City</label>
            <input
              type="text"
              name="product_city"
              required
              onChange={handleChange}
            />
          </div>

          <div className="input-group">
            <label>Brand</label>
            <input
              type="text"
              name="prouduct_brand"
              required
              onChange={handleChange}
            />
          </div>

          <div className="input-group">
            <label>Type</label>
            <input
              type="text"
              name="prouduct_type"
              required
              onChange={handleChange}
            />
          </div>

          <div className="input-group">
            <label>Images</label>
            <input
              type="file"
              name="images"
              required
              multiple
              onChange={handleUpload}
            />
          </div>

          <div className="buttons">
            <button onClick={back} className="btn-delete"> Back </button>
            <button disabled={disableBtn}> Add Product </button>
          </div>
        </div>
      </form>
    </Layout>
  );
}
 
export default AddProduct;