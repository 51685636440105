import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../images/Logo.png';


const NotFound = () => {
    return (  
        <div className='not-found'>
            <img className='logo-not-found' src={logo} alt='Logo' />
            <div className='redirect-contant'>
                <div className='not'> <span className='not-span'>404</span> Not <span className='not-span'>Found</span></div>
                <Link to="/" >
                    <button className='not-btn'> Go To Home </button>
                </Link>
            </div>
        </div>
    );
}
 
export default NotFound;