import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import axios from "axios";
import { toast } from "react-toastify";
import { useRecoilValue } from "recoil";
import { authState } from "../../recoil";
import Layout from "../../components/admin/Layout";

export default function Product() {
  const authToken = useRecoilValue(authState);
  const param = useParams();
  const navigate = useNavigate();
  const [product, setProduct] = useState([]);

  useEffect(() => {
    axios
      .post("/api/getvendorsproduct")
      .then((res) => {
        setProduct(res.data);
        console.log(res.data);
      })
      .catch((err) => {
        toast.error("Failed to get Products");
      });
  }, [authToken]);

  const back = () => {
    navigate("/products");
  };

  return (
    <Layout>
      <div className="product">
        <div>
          <div className="header">
            <button className="btn back-btn" onClick={back}>Back</button>
          </div>

          <div className="content">
            <div className="image">
              <img className="img" src={`https://api.myheroapp.shop/uploads/3/${product[3]?.product_images[0]}`} />
            </div>

            <div className="info">
              <h1>{product[3]?.product_title}</h1>
              <p className="description">{product[3]?.prouduct_describtion}</p>

              <div className="price mt">
                <p className="title">Was :</p>
                <p className="title was">{product[3]?.product_price} EGP</p>
              </div>

              <div className="price mt">
                <p className="title">Now :</p>
                <p className="now">200 EGP</p>
              </div>

              <div className="price mt">
                <p className="title">Saving :</p>
                <p>{product[3]?.product_price - 200} EGP</p>
                <p className="off">{parseInt((((product[3]?.product_price - 200)/product[3]?.product_price)*100),10)}% off</p>
              </div>

              <div className="price mt">
                <p className="title">City :</p>
                <p className="other">{product[3]?.product_city}</p>
              </div>

              <div className="price mt">
                <p className="title">Type :</p>
                <p className="other">{product[3]?.prouduct_type}</p>
              </div>

              <div className="price mt">
                <p className="title">Brand :</p>
                <p className="other">{product[3]?.prouduct_brand}</p>
              </div>

              <div className="actions">
                <button className="edit-btn">Edit</button>
                <button className="delete-btn">Delete</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
