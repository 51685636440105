import React, { useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import swal from "sweetalert";

const EditProduct = (props) => {
  const [newRow, setNewRow] = useState(props.row);

  const handleChange = ({ target: { name, value } }) => {
    setNewRow({ ...newRow, [name]: value });
  };

  const handelOnClick = (e) => {
    e.preventDefault();
    axios
      .patch("/api/update", newRow)
      .then((response) => {
        if (response.data.errno === 1062) toast.error("Already Exist");
        else
          swal("Data Updated", "success").then((val) =>
            window.location.reload()
          );
      })
      .catch((err) => toast.error("Error"));
  };

  const back = () => {
    props.hide();
  };

  return (
    <>
      <form className="user-edit-form">
        <h2>Edit Product</h2>

        <div className="fields">
          <div className="input-group">
            <label htmlFor="productname">Name</label>
            <input
              type="text"
              name="productname"
              id="productname"
              placeholder="Product Name"
              onChange={handleChange}
              value={newRow.productname}
              required
            />
          </div>

          <div className="input-group">
            <label htmlFor="type">Type</label>
            <input
              type="text"
              name="type"
              id="type"
              placeholder="Type"
              onChange={handleChange}
              value={newRow.type}
              required
            />
          </div>

          <div className="input-group">
            <label htmlFor="price">Price</label>
            <input
              type="number"
              name="price"
              id="price"
              placeholder="price"
              onChange={handleChange}
              value={newRow.price}
              required
            />
          </div>

          <div className="input-group">
            <label htmlFor="state">Status</label>
            <input
              type="text"
              name="state"
              id="state"
              placeholder="state"
              onChange={handleChange}
              value={newRow.state}
              required
            />
          </div>

          <div className="input-group">
            <label htmlFor="status">Shipment</label>
            <input
              type="text"
              name="shipment"
              id="shipment"
              placeholder="Shipment"
              onChange={handleChange}
              value={newRow.shipment}
              required
            />
          </div>

          <div className="input-group">
            <label htmlFor="shipmentprice">Ship Price</label>
            <input
              type="number"
              name="shipmentprice"
              id="shipmentprice"
              placeholder="Ship Price"
              onChange={handleChange}
              value={newRow.shipmentprice}
              required
            />
          </div>

          <div className="buttons">
            <button onClick={back}> Back </button>
            <button onClick={handelOnClick}> Submit </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default EditProduct;
