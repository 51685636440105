import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import MUIDataTable from "mui-datatables";
import { IconButton } from "@mui/material";
import EditIcon from "@material-ui/icons/Edit";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { createTheme } from "@material-ui/core/styles";
import { FaPlus } from "react-icons/fa";
import Layout from "../../components/admin/Layout";
import EditProduct from "../../components/forms/EditProduct";
import { useRecoilValue } from "recoil";
import { authState } from "../../recoil";

const Products = () => {
  const authToken = useRecoilValue(authState);
  const [products, setProducts] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editRow, setEditRow] = useState(-1);
  const [pageCount, setPageCount] = useState(0);
  const [totalPage, setTotalPage] = useState("");

  useEffect(() => {
    axios
      .post("/api/getvendorsproduct")
      .then((res) => {
        setProducts(res.data);
        setTotalPage(res.data.pages);
      })
      .catch((err) => {
        toast.error("Failed to get Products");
        document.location.reload(true);
      });
  }, [authToken]);

  const handelIncrement = () => {
    axios
      .post("/api/getvendorsproduct", {
        page: pageCount + 1,
      })
      .then((res) => {
        if (res.data.pages !== pageCount) {
          setProducts(res.data.data);
          setTotalPage(res.data.pages);
          setPageCount(pageCount + 1);
        }
      })
      .catch((err) => {
        toast.error("Failed to get Products");
      });
  };

  const handelDecrement = () => {
    axios
      .post("/api/getvendorsproduct", {
        page: pageCount - 1,
      })
      .then((res) => {
        setProducts(res.data.data);
        setTotalPage(res.data.pages);
        setPageCount(pageCount - 1);
      })
      .catch((err) => {
        toast.error("Failed to get Products");
      });
  };

  const columns = [
    { name: "id", label: "ID", options: { filterType: "textField" } },
    { 
      name: "product_title", 
      label: "Name", 
      options: { 
        filterType: "textField",
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <a href={`/products/${products[tableMeta.rowIndex].id}`}>
              {value}
            </a>
          );
        },
      } 
    },
    { name: "product_price", label: "Price", options: { filterType: "textField" } },
    { name: "product_city", label: "City", options: { filterType: "dropdown", filterOptions: ["Giza", "Cairo", "Alexandria"] } },
    { name: "prouduct_brand", label: "Brand", options: { filterType: "dropdown", filterOptions: ["Adidas", "Nike", "Other"] } },
    { name: "prouduct_type", label: "Type", options: { filterType: "dropdown", filterOptions: ["Wheelchair", "Medcine", "Other"] } },
    // {
    //   name: "Edit",
    //   options: {
    //     filter: false,
    //     sort: false,
    //     empty: true,
    //     customBodyRender: (value, tableMeta, updateValue) => {
    //       return (
    //         <IconButton
    //           style={{ order: -1, marginLeft: "-10px"}}
    //           onClick={() => {
    //             setEditRow(products[tableMeta.rowIndex]);
    //             setShowEditModal(true);
    //           }}
    //         >
    //           <EditIcon />
    //         </IconButton>
    //       );
    //     },
    //   },
    // },
  ];

  const options = {
    selectableRows: "multiple",
    draggableColumns: { enabled: true },
    jumpToPage: true,
    hasIndex: true,

    customFooter: (
      count,
      page,
      rowsPerPage,
      changeRowsPerPage,
      changePage,
      textLabels
    ) => {
      return (
        <div className="pagenation">
          {/* {pageCount > 0 && (
            <button className="left-arr" onClick={handelDecrement}>
              &lt;
            </button>
          )}

          {pageCount !== totalPage && (
            <button className="right-arr" onClick={handelIncrement}>
              &gt;
            </button>
          )} */}
        </div>
      );
    },

    onRowsDelete: (rowsDeleted) => {
      swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this field!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          for (var i = 0; i < rowsDeleted.data.length; i++) {
            axios
              .delete(`api`)
              .then((res) => {
                if (res.data.status) {
                  toast.success("Deleted Successfully");
                } else {
                  toast.error(`Failed with status ${res.data.status}`);
                }
              })
              .catch((err) => {
                toast.error("can't delete");
              });
          }
        } else {
          swal("The entry is safe!").then((val) => window.location.reload());
        }
      });
    },
  };

  const getMuiTheme = () =>
    createTheme({
      overrides: {
        MUIDataTableToolbar: {
          actions: {
            display: "flex",
            flexDirection: "row",
            flex: "initial",
          },
        },
        MuiToolbar: {
          root: {
            color: "#1fd0b4",
          },
        },
        MuiTableHead: {
          root: {
            color: "#1fd0b4",
          },
        },
      },
    });

  return (
    <Layout>
      {!showEditModal ? (
        <div className="products">
          <div className="header">
            <div className="title">
              Your Products
            </div>

            <Link to="/products/add">
              <button className="add-btn">
                <FaPlus className="icon" />
                Add Product
              </button>
            </Link>
          </div>

          <MuiThemeProvider theme={getMuiTheme()}>
            <MUIDataTable
              data={products}
              columns={columns}
              options={options}
            />
          </MuiThemeProvider>
        </div>
      ) : (
        <EditProduct
          show={showEditModal}
          hide={() => setShowEditModal(false)}
          columns={columns}
          btn="Add New Row"
          row={editRow}
        />
      )}
    </Layout>
  );
};

export default Products;
