const Footer = () => {
  return ( 
    <div className="footer">
      <div className="container inner-footer">
        © 2022 MY HERO Team
      </div>
    </div>
  );
}
 
export default Footer;