import React from 'react';
import Navbar from '../../components/sections/Navbar';
import About from '../../components/sections/About';
import Footer from '../../components/sections/Footer';
import Contact from '../../components/sections/Contact';

const Landing = () => {
    return (  
        <>
            <Navbar/>
            <About />
            <Contact />
            <Footer />
        </>
    );
}
 
export default Landing;