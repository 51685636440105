import React, { useState } from "react";
import Logo from "../../images/DashboardLogo.webp";
import { useSetRecoilState } from "recoil";
import { useFormik } from "formik";
import * as Yup from "yup";
import { authState } from "../../recoil";
import axios from "axios";
import { toast } from "react-toastify";
import { FaFacebookF, FaInstagram, FaTwitter, FaLinkedinIn } from 'react-icons/fa';

const Login = () => {
  document.title = "My Hero | Login";
  const [disabled, setDisabled] = useState(false);
  const setToken = useSetRecoilState(authState);
  
  const formik = useFormik({
    initialValues: {
      user_email: "",
      user_password: "",
    },
    validationSchema: Yup.object({
      user_email: Yup.string()
        .email("Invalid email address")
        .required("Required"),
      user_password: Yup.string()
        .min(6, "Must be 6 characters or more")
        .required("Required"),
    }),
    onSubmit: async (values) => {
      try {
        await handleSubmit(values);
      } catch (error) {
        console.log(error);
      }
    },
  });

  const handleSubmit = (values) => {
    setDisabled(true);
    axios
      .post("/api/signin", {
        user_email: values.user_email,
        user_password: values.user_password,
      })
      .then((response) => {
        setToken(response.data.token);
        localStorage.setItem("token", response.data.token);
      })
      .catch((err) => {
        toast.error("Your Credentials are wrong 😔");
        setDisabled(false);
      });
  };

  return (
    <div className="login">
      <div className="container">
        <div className="header">
          <div>
            <img src={Logo} alt="My Hero" />
          </div>

          <div className="form">
            <form onSubmit={formik.handleSubmit}>
              <div className="fields">
                <div className="input-group">
                  <input
                    className="input-login"
                    type="text"
                    name="user_email"
                    id="user_email"
                    placeholder="E-mail"
                    onChange={formik.handleChange}
                  />
                </div>

                <div className="input-group">
                  <input
                    className="input-login"
                    type="password"
                    name="user_password"
                    id="user_password"
                    placeholder="Password"
                    onChange={formik.handleChange}
                  />
                  <div className="forget-pass">
                    Forget Password?
                  </div>
                </div>

                <button 
                  className="btn-login" 
                  type="submit" 
                  disabled={disabled}
                >
                  Login
                </button>
              </div>
            </form>
          </div>
        </div>

        <div className="slogn">
          <div>Bringing You Closer</div>
          <div>To Your Customers.</div>
        </div>

        <div className="footer">
          <div className="icon"><FaFacebookF /></div>
          <div className="icon"><FaInstagram /></div>
          <div className="icon"><FaTwitter /></div>
          <div className="icon"><FaLinkedinIn /></div>
          <div className="name">My Hero</div>
        </div>
      </div>
    </div>
  );
};

export default Login;
