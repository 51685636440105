import React, { useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import swal from "sweetalert";

const EditUser = (props) => {
  const [newRow, setNewRow] = useState(props.row);

  const handleChange = ({ target: { name, value } }) => {
    setNewRow({ ...newRow, [name]: value });
  };

  const handelOnClick = (e) => {
    e.preventDefault();
    axios
      .patch("/api/update", newRow)
      .then((response) => {
        if (response.data.errno === 1062) toast.error("Already Exist");
        else
          swal("Data Updated", "success").then((val) =>
            window.location.reload()
          );
      })
      .catch((err) => toast.error("Error"));
  };

  const back = () => {
    props.hide();
  };

  return (
    <>
      <form className="user-edit-form">
        <h2>Edit User</h2>
        <div className="fields">
          <div className="input-group">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              name="name"
              id="name"
              placeholder="Name"
              onChange={handleChange}
              value={newRow.name}
              required
            />
          </div>

          <div className="input-group">
            <label htmlFor="email">E-mail</label>
            <input
              type="text"
              name="email"
              id="email"
              placeholder="example@domain.com"
              onChange={handleChange}
              value={newRow.email}
              required
            />
          </div>

          <div className="input-group">
            <label htmlFor="phone">Phone</label>
            <input
              type="text"
              name="phone"
              id="phone"
              placeholder="01234567899"
              onChange={handleChange}
              value={newRow.phone}
              required
            />
          </div>

          <div className="input-group">
            <label htmlFor="location">Location</label>
            <input
              type="text"
              name="location"
              id="location"
              placeholder="location"
              onChange={handleChange}
              value={newRow.location}
              required
            />
          </div>

          <div className="buttons">
            <button onClick={back}> Back </button>
            <button onClick={handelOnClick}> Submit </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default EditUser;
