import React from 'react';
import { HiMail } from 'react-icons/hi';

const Contact = () => {
    return ( 
        <section id='contact' className='contact'>
            <div className="container">
                <h1>Contact Us</h1>

                <a href='mailto:myheroapplication@gmail.com' target='blank' className='email'>
                    <span> <HiMail /> </span>
                    <p>myheroapplication@gmail.com</p>
                </a>
            </div>
        </section>
    );
}
 
export default Contact;
