import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import "./sass/main.scss";
import axios from 'axios';
import { RecoilRoot } from "recoil";

axios.defaults.baseURL = "https://api.myheroapp.shop";
axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem("token")}`;

ReactDOM.render(
  <React.StrictMode>
    <RecoilRoot>
      <App />
    </RecoilRoot>
  </React.StrictMode>,
  document.getElementById('root')
);