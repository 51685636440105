import React, { useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import Logo from "../../images/Logo.png";

const Navbar = () => {
  const [show, setShow] = useState(false);

  const showNavbar = () => {
    setShow(!show);
  };

  const noShow = () => {};

  return (
    <nav>
      <div className="container nav">
        <button className="mobile-menu" onClick={showNavbar}>
          {show ? <FaTimes /> : <FaBars />}
        </button>

        <img className="logo" src={Logo} alt="Logo" />

        <ul
          className={show ? "links block" : "links"}
          onClick={show ? showNavbar : noShow}
        >
          <li>
            <a href="/">Home</a>
          </li>
          <li>
            <a href="/">About</a>
          </li>
          <li>
            <a href="/#contact">Contact</a>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
