import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Login from './Pages/admin/Login';
import Dashboard from './Pages/admin/Dashboard';
import Users from './Pages/admin/Users';
import Products from './Pages/admin/Products';
import Orders from './Pages/admin/Orders';
import Order from './Pages/admin/Order';
import Settings from './Pages/admin/Settings';
import LandingPage from './Pages/user/LandingPage';
import PrivacyPolicy from './Pages/user/PrivacyPolicy';
import Terms from './Pages/user/Terms';
import Security from './Pages/user/Security';
import NotFound from './Pages/user/NotFound';
import AddUser from './components/forms/AddUser';
import AddProduct from './components/forms/AddProduct';
import { useRecoilValue } from "recoil";
import { authState } from "./recoil";
import { decodeToken } from "react-jwt";
import Product from './Pages/admin/Product';

function App() {
  const authToken = useRecoilValue(authState);
  const [token, setDecodeToken] = useState("");

  useEffect(() => {
    if (authToken) {
    const decode = decodeToken(authToken);
    setDecodeToken(decode);
    }
  }, [authToken]);

  const RedirectToLogin = () => {
    if (!authToken) return <Navigate to="/login" />;
    return <Outlet />;
  };

  const RedirectToApp = () => {
    if (authToken) return <Navigate to="/dashboard" />;
    return <Outlet />;
  };

  return (
    <>
      <Router>
        <div className="App">
          <ToastContainer />
          <Routes>
            <Route path='/' element={<LandingPage />} /> 
            <Route path='/privacy-policy' element={<PrivacyPolicy />} /> 
            <Route path='/terms' element={<Terms />} /> 
            <Route path='/security' element={<Security />} /> 

            <Route element={<RedirectToApp />} >
              <Route path="/login" element={<Login />} /> 
            </Route>
            
            <Route element={<RedirectToLogin />}>
              <Route path='/dashboard' element={<Dashboard />} /> 

              {token[0]?.user_role === "Admin" && (
                <>
                  <Route path="/users" element={<Users />} /> 
                  <Route path="/users/add" element={<AddUser />} />
                </>
              )}
                    
              {token[0]?.user_role === "Vendor" && (
                <>
                  <Route path="/products" element={<Products />} /> 
                  <Route path="/products/:id" element={<Product />} /> 
                  <Route path="/products/add" element={<AddProduct />} />  
                  <Route path="/orders" element={<Orders />} /> 
                  <Route path="/order/:id" element={<Order />} /> 
                </>
              )}   
              {/* <Route path="/settings" element={<Settings />} />   */}
            </Route>

            <Route path="*" element={<NotFound />} /> 
          </Routes>
        </div>
      </Router>
    </>
  );
}

export default App;
