import React, { useEffect } from 'react';
import Sidebar from './Sidebar';
import { motion } from 'framer-motion';

const Laayout = (props) => {
  useEffect(() => {
      document.title = "My Hero | Dashboard";
  }, []);

  return ( 
  <div className="dashboard">
    <Sidebar />
    <motion.main className="main-content" initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      {props.children}
    </motion.main>
  </div>
  );
}
 
export default Laayout;