import React from "react";

const Order = () => {
  return ( 
    <div>
      Order
    </div>
   );
}
 
export default Order;